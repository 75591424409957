import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { Button, ButtonType } from 'syngenta-digital-cropwise-react-ui-kit';
import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import { format } from 'date-fns';
import { generate } from 'shortid';

import { ReactComponent as DownloadIcon } from 'assets/download_outlined_green.svg';
import { Context } from 'components/Store';
import { getFieldCrops } from 'screens/Property/helpers/propertyDataHelpers';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import CropLegend, { renderCropLegendRows } from '../CropLegend';
import renderLegendLabelRows from './LegendLabelRows';
import PDFPage, { LEGEND_PREVIEW_ID, MAP_PREVIEW_ID } from './PDFPage';

const CROP_LEGEND_ROWS_PER_PAGE = 28;
const LEGEND_LABEL_ROWS_PER_PAGE = 20;

const WithExportPreview = ({ fields, children }) => {
  const amplitude = useContext(AmplitudeContext);
  const [
    {
      isExportPDFMapsActive,
      exportPDFMapsStep,
      fieldsToMassiveAssign,
      cropColors,
      mapLabelConfig,
      mapLegendLabels
    }
  ] = useContext(Context);
  const [containerWidth, setContainerWidth] = useState(792);
  const [containerHeight, setContainerHeight] = useState(612);
  const containerRef = useRef(null);

  const updateContainerDimensions = () => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
      setContainerHeight(containerRef.current.offsetHeight);
    }
  };

  useLayoutEffect(() => {
    updateContainerDimensions();
    window.addEventListener('resize', updateContainerDimensions);

    return () => {
      updateContainerDimensions();
      window.removeEventListener('resize', updateContainerDimensions);
    };
  }, [exportPDFMapsStep]);

  const [isExporting, setIsExporting] = useState(false);

  const separateLegend = useMemo(
    () => mapLabelConfig.labelLocation === 'legend',
    [mapLabelConfig]
  );
  const showCropLegend =
    !!fields.find(({ cropzones }) => cropzones.length) && !separateLegend;
  const crops = getFieldCrops(fields);

  const cropLegendPages = useMemo(() => {
    if (!separateLegend) return [];

    const _cropLegendRows = renderCropLegendRows({
      crops,
      cropColors,
      isExporting,
      size: 'large'
    });

    return _cropLegendRows.reduce((acc, _, i) => {
      if (i % CROP_LEGEND_ROWS_PER_PAGE === 0) {
        acc.push(_cropLegendRows.slice(i, i + CROP_LEGEND_ROWS_PER_PAGE));
      }
      return acc;
    }, []);
  }, [separateLegend, crops, cropColors, isExporting]);

  const legendLabelRows = separateLegend
    ? renderLegendLabelRows({
        fieldsToMassiveAssign,
        mapLegendLabels
      })
    : [];

  const numLegendLabelPages = Math.ceil(
    legendLabelRows.length / LEGEND_LABEL_ROWS_PER_PAGE
  );

  const handleDownloadPDF = () => {
    setIsExporting(true);
    amplitude.sendEventToAmplitude(
      amplitude.events.epic.Properties.selectExportOption
    );
  };

  useEffect(() => {
    if (!isExporting) return;

    async function exportPDF() {
      let input = document.getElementById(MAP_PREVIEW_ID);
      let canvas = await html2canvas(input, {
        useCORS: true
      });
      const pdf = new JsPDF({
        orientation: 'landscape',
        unit: 'px',
        format: [canvas.width, canvas.height]
      });

      const addPageToPDF = async id => {
        input = document.getElementById(id);
        canvas = await html2canvas(input, {
          useCORS: true
        });
        const imgData = canvas.toDataURL('image/png');
        if (id !== MAP_PREVIEW_ID) pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
      };

      await addPageToPDF(MAP_PREVIEW_ID);

      // Start at page 2, then add each legend page (property labels and crops)
      if (separateLegend) {
        for (
          let i = 2;
          i < numLegendLabelPages + cropLegendPages.length + 2;
          i += 1
        ) {
          // eslint-disable-next-line no-await-in-loop
          await addPageToPDF(`${LEGEND_PREVIEW_ID}_${i}`);
        }
      }

      const fileName = `Map_${format(new Date(), 'yyyy-MM-dd-HHmm')}.pdf`;
      pdf.save(fileName);
      setIsExporting(false);
    }
    exportPDF();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExporting]);

  if (!isExportPDFMapsActive || exportPDFMapsStep !== 1) {
    return <>{children}</>;
  }

  // Adjust for 8px padding on either side, divide by default dimension size
  const scaleWidth = (containerWidth - 16) / 792;
  const scaleHeight = (containerHeight - 16) / 612;
  const scale = Math.min(scaleWidth, scaleHeight);

  return (
    <div
      ref={containerRef}
      className="flex flex-col flex-grow items-center pt-2"
      style={{ backgroundColor: '#f2f4f6' }}
    >
      <div
        style={{
          transform: `scale(${scale})`,
          transformOrigin: 'top center'
        }}
      >
        <PDFPage
          id={MAP_PREVIEW_ID}
          pageNo={separateLegend ? 1 : undefined}
          mapLegend={
            <CropLegend
              crops={crops}
              cropColors={cropColors}
              show={showCropLegend}
              isExporting={isExporting}
              style={{
                bottom: 29,
                right: 9
              }}
              limit={25}
            />
          }
        >
          {children}
        </PDFPage>
        {separateLegend && (
          <>
            {Array.from({ length: numLegendLabelPages }).map((_, pageNo) => (
              <PDFPage
                pageNo={pageNo + 2}
                id={`${LEGEND_PREVIEW_ID}_${pageNo + 2}`}
                key={generate()}
              >
                <Row className="font-semibold">Map Legend</Row>
                <Row className="font-semibold text-12pt mt-2">Properties</Row>
                {legendLabelRows.slice(
                  pageNo * LEGEND_LABEL_ROWS_PER_PAGE,
                  (pageNo + 1) * LEGEND_LABEL_ROWS_PER_PAGE
                )}
              </PDFPage>
            ))}
            {cropLegendPages?.map((cropLegendRows, i) => (
              <PDFPage
                pageNo={i + numLegendLabelPages + 2}
                id={`${LEGEND_PREVIEW_ID}_${i + numLegendLabelPages + 2}`}
                key={generate()}
              >
                <Row className="font-semibold">Map Legend</Row>
                <Row
                  className="font-semibold mt-4 mb-1"
                  style={{ fontSize: 12 }}
                >
                  Crops
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    {cropLegendRows
                      .slice(0, Math.ceil(CROP_LEGEND_ROWS_PER_PAGE / 2))
                      .map(row => (
                        <div key={generate()}>{row}</div>
                      ))}
                  </Col>
                  <Col span={12} className="-mt-1">
                    {cropLegendRows
                      .slice(Math.ceil(CROP_LEGEND_ROWS_PER_PAGE / 2))
                      .map(row => (
                        <div key={generate()}>{row}</div>
                      ))}
                  </Col>
                </Row>
              </PDFPage>
            ))}
          </>
        )}
      </div>
      <div
        className="fixed z-1 flex justify-center p-4 bg-white rounded-lg shadow-sm-card"
        style={{ bottom: 24 }}
      >
        <Row gutter={8} justify="center">
          {/* To implement in future stories */}
          {/* <Col>
            <Button type={ButtonType.primary} ghost size="large">
              Email
            </Button>
          </Col>
          <Col>
            <Button type={ButtonType.primary} ghost size="large">
              Print
            </Button>
          </Col> */}
          <Col>
            <Button
              type={ButtonType.primary}
              ghost
              size="large"
              onClick={handleDownloadPDF}
            >
              <Row gutter={4} align="middle">
                <Col>
                  <DownloadIcon />
                </Col>
                <Col>Download PDF</Col>
              </Row>
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

WithExportPreview.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      area: PropTypes.number.isRequired
    })
  ).isRequired,
  children: PropTypes.node.isRequired
};

export default WithExportPreview;
