import React, { useContext, useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { MapContext } from 'react-mapbox-gl';
import { center, points } from '@turf/turf';
import { featureCollection } from '@turf/helpers';
import { useLocation } from 'react-router-dom';
import { flatMap, debounce, flattenDeep, isEqual } from 'lodash';

import { Context } from 'components/Store';
import { getUpdatedBoundingBox } from 'screens/Property/helpers/propertyDataHelpers';
import { LAST_COORDINATES_STORED } from 'screens/Property/helpers/constants';
import {
  SET_MAP_PRINTABLE_AREA_BBOX,
  IS_MAP_MOVING,
  SET_MAP_LEGEND_LABELS
} from 'reducers/reducer';
import {
  calculatePrintableAreaBoundingBox,
  calculateZoomOffset,
  getCenterOfBoundingBox
} from 'screens/Property/helpers/mapApiHelpers';
import createAction from 'helpers/createAction';
import PropertyMapContext from '../../PropertyMapContext';
import CustomLayers, {
  buildCustomLabel,
  CUSTOM_LAYER_TYPE,
  FOCUSED_LAYER_TYPE,
  SELECTED_LAYER_TYPE
} from '../PropertyMap/utilities/mapboxLayerHelpers';
import SelectedLayer from './components/SelectedLayer/SelectedLayer';
import DynamicLayer from './components/DynamicLayer';

const PropertyFeatureLayer = ({
  geoJSON,
  customStyles,
  visible,
  setZoom,
  activeObjectType,
  isGeoJSONLabelEnabled
}) => {
  const { pathname } = useLocation();
  const [
    {
      selectedProperty,
      loadingProperties,
      loadTimestamp,
      fieldsToMassiveAssign,
      isExportPDFMapsActive,
      isMassAssignerActive,
      exportPDFMapsStep,
      mapPrintableAreaBbox,
      cropColors,
      mapLabelConfig
    },
    dispatch
  ] = useContext(Context);
  const [boundingBox, setBoundingBox] = useState();
  const [mapIsReady, setMapIsReady] = useState(false);
  const [boxIsMoving, setBoxIsMoving] = useState(false);

  const isMultipleSelect = isExportPDFMapsActive;

  const isAnimateActive = useRef(true);
  const selectedJSON = useMemo(
    () =>
      featureCollection(
        geoJSON?.features?.filter(
          ({ properties }) => properties.$layer === SELECTED_LAYER_TYPE
        ) || []
      ),
    [geoJSON]
  );

  const taggedGeoJSON = useMemo(() => {
    if (
      !isMultipleSelect &&
      !isMassAssignerActive &&
      fieldsToMassiveAssign.length === 0
    ) {
      return geoJSON;
    }

    // Start cropZoneLegendKey at number of unique cropzones + 1 to avoid displaying 0 to the user
    let cropZoneLegendKey =
      new Set(
        flattenDeep(fieldsToMassiveAssign.map(({ cropzones }) => cropzones))
      ).size + 1;
    const taggedFeatures = geoJSON.features
      .filter(({ properties }) => !!properties.id)
      .map(feature => {
        const isCustom =
          Object.keys(customStyles ?? {}).includes(feature.properties.$layer) ||
          fieldsToMassiveAssign.find(
            field => field.id === feature.properties.id
          );
        const hasCrop = !!feature.properties.crop?.id;

        const customLabel = buildCustomLabel(
          feature.properties,
          mapLabelConfig,
          fieldsToMassiveAssign,
          exportPDFMapsStep === 0
        );

        if (feature.properties.$landType === 'cropzone') {
          cropZoneLegendKey -= 1;
        }

        return {
          ...feature,
          properties: {
            ...feature.properties,
            cropColor:
              isCustom && hasCrop && exportPDFMapsStep === 1
                ? cropColors[feature.properties.crop.id]
                : undefined,
            $layer: isCustom ? CUSTOM_LAYER_TYPE : feature.properties.$layer,
            customLabel,
            cropZoneLegendKey:
              feature.properties.$landType === 'cropzone'
                ? cropZoneLegendKey
                : undefined
          }
        };
      });
    return featureCollection(taggedFeatures);
  }, [
    geoJSON,
    customStyles,
    cropColors,
    isMultipleSelect,
    fieldsToMassiveAssign,
    exportPDFMapsStep,
    mapLabelConfig,
    isMassAssignerActive
  ]);

  const customJSONArr = useMemo(
    () =>
      Object.keys(customStyles ?? {}).map(key =>
        (taggedGeoJSON.features || []).filter(
          ({ properties }) =>
            properties.$layer === key || properties.$layer === CUSTOM_LAYER_TYPE
        )
      ),
    [taggedGeoJSON, customStyles]
  );

  const map = useContext(MapContext);

  if (!mapIsReady && map && !map.isMoving() && map.isStyleLoaded()) {
    setMapIsReady(true);
  }

  useEffect(() => {
    if (selectedProperty?.id) {
      isAnimateActive.current = true;
    }
  }, [selectedProperty]);

  useEffect(() => {
    if (!taggedGeoJSON?.features || exportPDFMapsStep !== 1) return;
    const newMapLegendLabels = {};
    taggedGeoJSON.features.forEach(feature => {
      if (feature.properties.$landType === 'cropzone') {
        newMapLegendLabels[feature.properties.cropZoneLegendKey] =
          feature.properties.customLabel;
      }
    });
    createAction(dispatch, SET_MAP_LEGEND_LABELS, newMapLegendLabels);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportPDFMapsStep, mapLabelConfig]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleZooming = bounds => {
    map.fitBounds(bounds, {
      padding: 160,
      animate: isAnimateActive.current
    });
    setBoundingBox(bounds);
  };

  // adjust the bounding box so that the current zoom level can be determined
  useEffect(() => {
    const selectedIds = flatMap(
      fieldsToMassiveAssign.map(field => {
        const cropZoneIds =
          field?.cropzones?.map(cropzone => cropzone.id) ?? [];
        return [field.id, ...cropZoneIds];
      })
    );

    const focusedGeoJSONFeatures = taggedGeoJSON?.features.filter(feature => {
      if (
        !isMultipleSelect &&
        !isMassAssignerActive &&
        fieldsToMassiveAssign.length === 0
      ) {
        return feature?.properties?.$layer === FOCUSED_LAYER_TYPE;
      }
      return selectedIds.includes(feature?.properties?.id);
    });

    const fc = focusedGeoJSONFeatures.length
      ? featureCollection(focusedGeoJSONFeatures)
      : geoJSON;

    const newBounds = getUpdatedBoundingBox(
      boundingBox,
      fc,
      focusedGeoJSONFeatures.length
    );

    if (fc && newBounds && mapIsReady && !boxIsMoving) {
      if (newBounds.find(bound => bound === Infinity || bound === -Infinity)) {
        return;
      }

      const newLng = newBounds.slice(0, 2);
      const newLat = newBounds.slice(-2);

      const feature = points([newLng, newLat]);
      const centroid = center(feature);

      localStorage.setItem(
        LAST_COORDINATES_STORED,
        JSON.stringify(centroid.geometry.coordinates)
      );

      if (
        isExportPDFMapsActive &&
        exportPDFMapsStep === 1 &&
        mapPrintableAreaBbox &&
        !isEqual(mapPrintableAreaBbox.bbox, boundingBox)
      ) {
        const oldContainer = map.getContainer();
        const newWidth = oldContainer.clientWidth;
        const oldWidth = mapPrintableAreaBbox.containerWidth;

        const oldZoom = map.getZoom();
        const newZoom = oldZoom + calculateZoomOffset(newWidth, oldWidth);

        map.jumpTo({
          center: getCenterOfBoundingBox(mapPrintableAreaBbox.bbox),
          zoom: newZoom
        });
        setBoundingBox(mapPrintableAreaBbox.bbox);
      } else {
        handleZooming(newBounds);
      }
      setBoxIsMoving(true);
    }
  }, [
    boundingBox,
    geoJSON,
    map,
    boxIsMoving,
    mapIsReady,
    isMultipleSelect,
    fieldsToMassiveAssign,
    taggedGeoJSON?.features,
    isExportPDFMapsActive,
    exportPDFMapsStep,
    mapPrintableAreaBbox,
    isMassAssignerActive,
    handleZooming
  ]);

  useEffect(() => {
    if (loadingProperties) {
      setBoundingBox(undefined);
    }
  }, [loadingProperties, loadTimestamp]);

  // wait for the bounding box movement to stop then set zoom to the map's current zoom value
  useEffect(() => {
    let mounted = true;

    const handleMove = debounce(async () => {
      // await set the zoom based on the map's new zoom level
      setZoom(() => {
        const newZoom = map.getZoom();
        return [newZoom];
      });
      if (exportPDFMapsStep !== 1) {
        createAction(dispatch, SET_MAP_PRINTABLE_AREA_BBOX, {
          containerWidth: map.getContainer().clientWidth,
          bbox: calculatePrintableAreaBoundingBox(map)
        });
      }
      createAction(dispatch, IS_MAP_MOVING, map.isMoving());
    }, 50);

    const moveChecker = debounce(() => {
      if (mounted) {
        setZoom(() => {
          const newZoom = map.getZoom();
          return [newZoom];
        });
        createAction(dispatch, IS_MAP_MOVING, false);
        setBoxIsMoving(false);
      }
    }, 300);

    if (map && (pathname === '/app/property' || boxIsMoving)) {
      map.on('moveend', moveChecker);
    }
    if (map && isExportPDFMapsActive && exportPDFMapsStep === 0) {
      map.on('move', handleMove);
      map.on('drag', handleMove);
      map.on('dragend', handleMove);
      map.on('zoom', handleMove);
    }

    return () => {
      mounted = false;
      if (map) {
        map.off('moveend', moveChecker);
        map.off('move', handleMove);
        map.off('drag', handleMove);
        map.off('zoom', handleMove);
      }
      moveChecker.cancel();
    };
  }, [
    map,
    boundingBox,
    boxIsMoving,
    setZoom,
    pathname,
    dispatch,
    exportPDFMapsStep,
    isExportPDFMapsActive
  ]);

  let labelKey;
  if (isMultipleSelect) {
    labelKey = 'name';
  } else if (activeObjectType === 'field' || activeObjectType === 'cropzone') {
    labelKey = 'fieldAndCropZoneLabel';
  } else {
    labelKey = 'defaultLabel';
  }

  const showLegendLabels =
    exportPDFMapsStep === 1 && mapLabelConfig.labelLocation === 'legend';

  if (!geoJSON || !visible) {
    return null;
  }
  return (
    <PropertyMapContext.Consumer>
      {({ onClickShape }) => (
        <>
          <SelectedLayer onClickShape={onClickShape} geoJSON={selectedJSON} />
          {customStyles && (
            <CustomLayers
              geoJSONArr={customJSONArr}
              stylesArr={Object.values(customStyles)}
              onClickShape={onClickShape}
              isMultipleSelect={isMultipleSelect}
              showLegendLabels={showLegendLabels}
            />
          )}
          <DynamicLayer
            onClickShape={onClickShape}
            geoJSON={taggedGeoJSON}
            labelKey={labelKey}
            isGeoJSONLabelEnabled={isGeoJSONLabelEnabled}
          />
        </>
      )}
    </PropertyMapContext.Consumer>
  );
};

PropertyFeatureLayer.propTypes = {
  geoJSON: PropTypes.objectOf(),
  customStyles: PropTypes.objectOf(
    PropTypes.shape({
      fillColor: PropTypes.string,
      fillOutlineColor: PropTypes.string
    })
  ),
  visible: PropTypes.bool,
  setZoom: PropTypes.func,
  activeObjectType: PropTypes.string.isRequired,
  isGeoJSONLabelEnabled: PropTypes.bool.isRequired
};

PropertyFeatureLayer.defaultProps = {
  geoJSON: null,
  customStyles: null,
  visible: false,
  setZoom: () => {}
};

export default PropertyFeatureLayer;
