import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Row, Col, Switch } from 'antd';

import { Context } from 'components/Store';
import createAction from 'helpers/createAction';
import { filterUniqueItems } from 'screens/Property/helpers/propertyDataHelpers';
import { SET_PROPERTY_MAP_FOOTER_CONFIG } from 'reducers/reducer';
import CollapsibleSection from '../CollapsibleSection';
import './index.css';

export const switchConfigs = [
  { key: 'organizationName', label: 'Organization name' },
  { key: 'farmNames', label: 'Farm names' },
  { key: 'cropSeasonNames', label: 'Crop season names' }
];

const FooterLabelsSelection = () => {
  const [hasCropSeasonsSelected, setHasCropSeasonsSelected] = useState();
  const [
    { propertyMapFooterConfig, fieldsToMassiveAssign },
    dispatch
  ] = useContext(Context);

  const handleSwitchChange = key => {
    createAction(dispatch, SET_PROPERTY_MAP_FOOTER_CONFIG, {
      [key]: !propertyMapFooterConfig[key]
    });
  };

  const cropSeasons = useMemo(
    () =>
      filterUniqueItems(fieldsToMassiveAssign, field =>
        field?.cropzones.flatMap(cropZone => cropZone?.cropSeasons ?? [])
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    const hasCropSeasons = cropSeasons.length > 0;
    setHasCropSeasonsSelected(hasCropSeasons);
    if (!hasCropSeasons) {
      createAction(dispatch, SET_PROPERTY_MAP_FOOTER_CONFIG, {
        cropSeasonNames: hasCropSeasons
      });
      return;
    }
    createAction(dispatch, SET_PROPERTY_MAP_FOOTER_CONFIG, {
      cropSeasonNames: hasCropSeasons
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cropSeasons]);

  return (
    <CollapsibleSection title="Page footer">
      <div className="px-4 pb-6">
        <span className="text-neutral-60">Footer contains:</span>
        {switchConfigs.map(({ label, key }) => {
          const isDisabled =
            key === switchConfigs[2].key && !hasCropSeasonsSelected;
          return (
            <Row key={key} className="mt-2" gutter={8}>
              <Col>
                <Switch
                  name={key}
                  checked={propertyMapFooterConfig[key]}
                  onChange={() => handleSwitchChange(key)}
                  disabled={isDisabled}
                />
              </Col>
              <Col className={isDisabled ? 'text-gray-500' : ''}>{label}</Col>
            </Row>
          );
        })}
      </div>
    </CollapsibleSection>
  );
};

export default FooterLabelsSelection;
