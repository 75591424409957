import React, { useContext, useEffect, useState } from 'react';
import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import useFormikHandler from 'screens/Integrations/hooks/useFormikHandler';
import useSubmissionAnalytics from 'screens/Integrations/hooks/useSubmissionAnalytics';
import { useHistory } from 'react-router-dom';
import SubmissionFooter from 'screens/Integrations/components/SubmissionFooter';
import { paths } from 'routes/paths';
import SpinnerModal from 'components/SpinnerModal';
import StoreIcon from 'components/Icons/StoreIcon';
import useIncomingInvoiceApi from 'screens/Integrations/hooks/useIncomingInvoiceApi';
import { Button, Tag } from '@agconnections/grow-ui';
import IntegrationSuccess from 'screens/Integrations/components/IntegrationSuccess';
import { AgVendContext } from '../context/Provider';
import AgVendInvoicesReviewTable from './components/AgVendInvoicesReviewTable';

const AgVendReviewAndImport = () => {
  const history = useHistory();
  const { events, triggerAnalyticsEvent } = useSubmissionAnalytics();

  const { gotoStep } = useContext(AgVendContext);

  const {
    loading: isLoading,
    importIncomingInvoices
  } = useIncomingInvoiceApi();

  const {
    selectedInvoices,
    setSelectedInvoices,
    selectedCropSeasons
  } = useFormikHandler();

  const [invoicesByRetailer, setInvoicesByRetailer] = useState([]);
  const [invoicesImported, setInvoicesImported] = useState(false);

  const splitInvoicesByRetailer = invoices => {
    const groupedByRetailer = new Map();

    invoices.forEach(invoice => {
      const { retailerName } = invoice;
      if (!groupedByRetailer.has(retailerName)) {
        groupedByRetailer.set(retailerName, [invoice]);
      } else {
        groupedByRetailer.get(retailerName).push(invoice);
      }
    });
    return groupedByRetailer;
  };

  useEffect(() => {
    if (selectedInvoices?.length === 0) {
      gotoStep(1);
    }

    if (selectedInvoices?.length > 0) {
      setInvoicesByRetailer(splitInvoicesByRetailer(selectedInvoices));
    }
  }, [selectedInvoices, gotoStep]);

  const onRemoveHandler = invoiceNumber => {
    setSelectedInvoices(
      selectedInvoices.filter(inv => inv.invoiceNumber !== invoiceNumber)
    );
  };

  useEffect(() => {
    triggerAnalyticsEvent(
      events.epic.Integrations.IncomingInvoice.invoiceReview,
      {
        vendor: INTEGRATIONS.agVend
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onImportHandler = () => {
    triggerAnalyticsEvent(
      events.epic.Integrations.IncomingInvoice.invoiceImport,
      {
        vendor: INTEGRATIONS.agVend
      }
    );
    importIncomingInvoices({
      invoices: {
        seasonId: selectedCropSeasons[0].id,
        ids: selectedInvoices.map(invoice => invoice.id)
      }
    }).then(() => {
      setInvoicesImported(true);
    });
  };

  return (
    <>
      {invoicesImported ? (
        <IntegrationSuccess
          title="Your data has been imported!"
          content={
            <>
              <p className="text-xs font-normal">
                Your AgVend invoices have been imported.
              </p>
              <p className="text-xs font-normal">
                You can see them on the Invoices page.
              </p>
            </>
          }
        >
          <div className="flex flex-row">
            <div className="mr-2">
              <Button
                type="primary"
                onClick={() => history.replace(paths.integrations)}
              >
                Return to Integrations Page
              </Button>
            </div>
            .
            <Button
              type="outline"
              onClick={() => history.replace(paths.invoices)}
            >
              Go to Invoices
            </Button>
          </div>
        </IntegrationSuccess>
      ) : (
        <>
          <SpinnerModal
            open={isLoading}
            message="Importing records from AgVend"
          />
          <div className="w-full mx-6">
            <div
              className="flex flex-col bg-white top-30 left-26 rounded-md pt-6 pl-6 pr-6 shadow-sm"
              data-testid="agvend-incoming-invoice-review"
            >
              <div>
                <div className="flex flex-col">
                  <div className="text-lg leading-7 font-semibold text-neutral-1000 mb-2">
                    Review and Import
                  </div>
                  <p className="text-sm leading-6 text-neutral-70">
                    Review matched data before creating invoices
                  </p>
                </div>
                <div className="h-px w-full bg-gray-500 mt-6 mb-6" />
                <div className="w-full flex flex-row h-15 items-center font-semibold">
                  <p className="text-2xl">
                    AgVend Import {new Date().toLocaleDateString('en-Us')}
                  </p>
                  <div className="ml-4">
                    <Tag color="#217c74">
                      <span className="p-1 text-white inline-block w-fit">
                        {selectedInvoices.length}{' '}
                        {`${
                          selectedInvoices.length > 1 ? 'Invoices' : 'Invoice'
                        } Total`}
                      </span>
                    </Tag>
                  </div>
                </div>
              </div>
              {[...invoicesByRetailer.keys()].map(key => {
                const invoices = invoicesByRetailer.get(key);
                if (invoices.length <= 0) {
                  return null;
                }

                return (
                  <div key={key}>
                    <div className="w-full flex mt-10">
                      <div className="flex flex-col justify-start w-full">
                        <div data-testid="invoice-retailer">
                          <div className="flex items-center">
                            <div className="flex flex-row items-center text-xl font-semibold mr-10">
                              <StoreIcon />
                              <span
                                className="ml-2 text-gray-700"
                                data-testid="retailer-name"
                              >
                                {key}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          className="flex flex-col bg-white rounded-md mb-4 mt-4 shadow-sm w-full"
                          data-testid="agvend-invoice-review-list"
                        >
                          <AgVendInvoicesReviewTable
                            invoices={invoices}
                            handleRemove={onRemoveHandler}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <SubmissionFooter
            nextDisabled={
              !selectedInvoices?.length || !selectedCropSeasons?.length
            }
            onNext={onImportHandler}
            isLastStep={false}
            context={AgVendContext}
            nextLabel="Import Records"
            integrationType={INTEGRATIONS.agVend}
            isBottomFixed
          />
        </>
      )}
    </>
  );
};

export default AgVendReviewAndImport;
